import { Provider as ReduxProvider, ProviderProps } from "react-redux";
import { ZendeskConfig } from "components/feedback/zenDeskConfig";

const ZendeskInit = (props: { store: ProviderProps["store"] }) => {
    return (
        <ReduxProvider store={props.store}>
            <ZendeskConfig />
        </ReduxProvider>
    );
};

export default ZendeskInit;
