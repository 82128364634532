import { connect } from "react-redux";
import App from "./App";
import { PublicProps, StoreProps } from "./appInterfaces";
import withAppInsights from "bootstrap/insights";
import { AppState } from "store";
import * as auth from "store/authentication";

export const mapStateToProps = (state: AppState) => ({ user: auth.selectors.user(state) });

export default connect<StoreProps, PublicProps>(
    mapStateToProps,
)(withAppInsights(App));
